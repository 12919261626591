export const SUBSCRIBE_BASE_AA = "SUBSCRIBE_BASE_AA";
export const OPEN_NETWORK = "OPEN_NETWORK";
export const CLOSE_NETWORK = "CLOSE_NETWORK";
export const LOAD_AA_LIST_SUCCESS = "LOAD_AA_LIST_SUCCESS";
export const LOAD_AA_LIST_REQUEST = "LOAD_AA_LIST_REQUEST";
export const ADD_AA_TO_LIST = "ADD_AA_TO_LIST";
export const SUBSCRIBE_AA = "SUBSCRIBE_AA";
export const CHANGE_ACTIVE_AA = "CHANGE_ACTIVE_AA";
export const ASSET_REQUEST = "ASSET_REQUEST";
export const ASSET_RESPONSE = "ASSET_RESPONSE";

export const UPDATE_INFO_ACTIVE_AA = "UPDATE_INFO_ACTIVE_AA";

export const ADD_COLLATERAL = "ADD_COLLATERAL";
export const LOAN_REPAY = "LOAN_REPAY";
export const EXPIRY_RATE = "EXPIRY_RATE";
export const EXPIRY_STATUS = "EXPIRY_STATUS";
export const ISSUE_STABLE_COIN = "ISSUE_STABLE_COIN";
export const ADD_STABLE_COIN_TO_AUCTION = "ADD_STABLE_COIN_TO_AUCTION";
export const UPDATE_RATE = "UPDATE_RATE";

export const ADD_SYMBOL_BY_AA = "ADD_SYMBOL_BY_AA";

export const AAS_TOTAL_COIN_LIST_REQUEST = "AAS_TOTAL_COIN_LIST_REQUEST";
export const AAS_TOTAL_COIN_LIST_SUCCESS = "AAS_TOTAL_COIN_LIST_SUCCESS";
